var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "LessonEntityDetails__Component",
      class: [_vm.isEntityPage && "disabled"]
    },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם המקבץ", readOnly: _vm.isEntityPage },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("div", { staticClass: "checkbox-container" }, [
        _c(
          "div",
          [
            _c(
              "b-switch",
              {
                staticStyle: { width: "200px" },
                attrs: { disabled: _vm.isEntityPage },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.model.displaySeperateLawText,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "displaySeperateLawText", $$v)
                  },
                  expression: "model.displaySeperateLawText"
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.model.displaySeperateLawText
                      ? "דרופדאון"
                      : "ללא דרופדאון"
                  )
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-switch",
              {
                staticStyle: { width: "200px" },
                attrs: { disabled: _vm.isEntityPage },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.model.isAcademy,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "isAcademy", $$v)
                  },
                  expression: "model.isAcademy"
                }
              },
              [_vm._v(_vm._s(_vm.model.isAcademy ? "אקדמיה" : "מתמחה"))]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-switch",
              {
                staticStyle: { width: "200px" },
                attrs: { disabled: _vm.isEntityPage },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.model.isActive,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "isActive", $$v)
                  },
                  expression: "model.isActive"
                }
              },
              [_vm._v(_vm._s(_vm.model.isActive ? "פעיל" : "לא פעיל"))]
            )
          ],
          1
        )
      ]),
      _vm.model.laws && _vm.model.laws.length
        ? _c(
            "b-field",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { label: "חוקים מקושרים" }
            },
            [
              _c(
                "div",
                { staticClass: "laws" },
                _vm._l(_vm.model.laws, function(law) {
                  return _c("div", { key: law.id, staticClass: "law" }, [
                    _vm._v(" " + _vm._s(law.lawName) + " ")
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm.model.videos && _vm.model.videos.length
        ? _c(
            "b-field",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { label: "סרטונים מקושרים" }
            },
            [
              _c(
                "div",
                { staticClass: "videos" },
                _vm._l(_vm.model.videos, function(video) {
                  return _c("div", { key: video.id, staticClass: "video" }, [
                    _vm._v(" " + _vm._s(video.name) + " ")
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm.model.exercises && _vm.model.exercises.length
        ? _c(
            "b-field",
            {
              staticStyle: { "margin-top": "15px" },
              attrs: { label: "תרגולים מקושרים" }
            },
            [
              _c(
                "div",
                { staticClass: "exercises" },
                _vm._l(_vm.model.exercises, function(exercise) {
                  return _c(
                    "div",
                    { key: exercise.id, staticClass: "exercise" },
                    [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(exercise.name) + " ")
                      ]),
                      exercise.lessonName
                        ? _c("span", [
                            _vm._v("מיחידת הלימוד "),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(exercise.lessonName) + " ")]
                            )
                          ])
                        : _vm._e(),
                      exercise.courseName
                        ? _c("span", [
                            _vm._v("בקורס "),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(exercise.courseName) + " ")]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }