<template>
  <div class="LessonEntityDetails__Component">
    <!-- <FieldInlineTextArea label="שם השאלה" @onChange="onChange" v-model="model.question" /> -->

    <b-field label="שאלה">
      <ckeditor @input="onChange" :editor="editor" v-model="model.question" :config="editorConfig"></ckeditor>
    </b-field>

    <FieldInlineText label="הערות עורך" @onChange="onChange" v-model="model.editorNotes" />

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
    }}</b-switch>
    </div>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
// import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineTextArea,
    // FieldInlineDate,
    // FieldInlineSelect,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        question: null,
        editorNotes: null,
        status: null,
      },
      editor: ClassicEditor,
      editorConfig: {
        language: {
          ui: 'he',
        }
      }
    };
  },
  computed: {
    ...mapGetters('question', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('question', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
